<template>
  <div style="display:flex; justify-content: start;">
   <div>
     <div class="contenedor-blanco">
       <div class="adeIn first text-center">
         <img  style="margin-top: 14vh;" alt="Vue logo" width="45%" src="../assets/logo_dentalia.png"/>
       </div>
       <div class="text-center formulario">
         <br/>
         <input
             type="text"
             id="user"
             class="fadeIn second inicioTexto"
             name="username"
             v-model="nombreUsuario"
             placeholder="Usuario"
         />
         <br/>
         <br/>
         <input
             type="password"
             id="password"
             class="fadeIn third inicioPass"
             name="password"
             v-model="contrasena"
             placeholder="Contraseña"
         />
         <br/>
         <div class="mt-2 fadeIn third enMedio">
           <p style="font-size: 1.1rem;">
             <a v-on:click="resetPass" class="underlineHover">¿Has olvidado la contraseña?</a>
           </p>
         </div>

         <input
             type="button"
             class="fadeIn fourth button-sesion"
             v-on:click="loguear"
             value="Iniciar sesión"
             v-if="!cargando"
         />
         <b-button class="mb-4" v-else size="lg" variant="primary">
           <b-spinner></b-spinner>
           Cargando...
         </b-button>
         <br/>
         <br/>
         <div class="mt-2 fadeIn third enMedio">
           <b-form-checkbox id="separado" value="true" v-model="terminos" size="sm">
           </b-form-checkbox>
           <p style="font-size: .8rem;">
             Acepto los
             <a class="underlineHover" v-on:click="abrirTerminos">
               términos y condiciones
             </a>
             para el uso del portal.
           </p>
         </div>
       </div>
       <div class=" diente">
         <img  alt="diente" src="../assets/icons/diente_trans.png"/>
       </div>
     </div>
   </div>
    <b-modal
        ref="terminos"
        size="xl"
        title="Términos y Condiciones de uso del Portal"
        header-class="fondoSecundario"
        hide-footer
    >
      <condiciones/>
    </b-modal>
  </div>

</template>

<script>
import condiciones from "./Terminos.vue";

export default {
  name: "login",
  components: {
    condiciones,
  },
  data() {
    return {
      nombreUsuario: "",
      contrasena: "",
      terminos: false,
      cargando: false,
      sistemaOperativo: "",
      lat: "",
      long: "",
      navegador: "",
      ipCliente:"",
      idUsuarioControl: null
    };
  },
 created() {
      this.obtenerIp();
    this.obtenerUbicacion();
    this.sistemaOperativo = this.obtenerSO();
   this.navegador = this. obtenerNavegador();
  },
  methods: {
    loguear() {
      if (this.terminos) {
        this.cargando = true;
        this.loginSistema();
      } else {
        this.$swal({
          icon: "warning",
          title: "Por favor indica que aceptas los Términos y Condiciones de uso.",
          confirmButtonText: "OK",
        });
      }
    },
    loginSistema() {
      this.$session.destroy();
      const url = this.$baseBackControlAcceso + "/login";
      const mensajeError = "Usuario o contraseña incorrectos";
      let parametros = {
        nombreUsuario: this.nombreUsuario,
        contrasena: this.contrasena,
        sistema: 3
      };

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      };

      this.$http
          .post(url, parametros, {headers: headers})
          .then((response) => {
            this.$session.set("token", response.headers.authorization);
            this.$session.set("refres_token", response.data['refresh-token']);
            this.$session.set("idControlAcceso", parseInt(response.data['CONTROL']));
          })
          .then(() => {
            this.$http.post(this.$baseBack + '/me').then( response => {
              this.$session.start();
              this.$localStorageService.asignarUsuarioAutenticado(response.data.data);
            
            }).then(()=> {
              if (this.$localStorageService.getIdEmpresa() == null) {
                this.mostrarMensajeError(mensajeError);
              } else {
                if (this.$localStorageService.getError()) {
                  this.mostrarMensajeError(
                      this.$localStorageService.getTipoError()
                  );
                } else {
                  this.$router.push({ name: "Inicio" });
                }
              }
              setTimeout(()=>{
                if(this.$localStorageService.getError() == false){
                  this.guardarBitacora("EXITO", this.$localStorageService.getIdUsuario());
                 }
              },5000);
            })
          })
          .catch((err) => {
            if (err.response.data.message.toLowerCase().indexOf('badcredentialsexception') > -1) {
              this.mostrarMensajeInfo(err.response.data.message.split(':')[1]);
            }else{
              this.mostrarMensajeError(mensajeError);
            }
            this.guardarBitacora("USUARIO Y CONTRASEÑA INCORRECTA", null);
          })
          .finally(() => {
            this.cargando = false;
            setTimeout(()=>{
            if(this.$localStorageService.getTipoError().toUpperCase().indexOf("LA CAMPAÑA HA TERMINADO") >= 0){
                    this.guardarBitacora("LA CAMPAÑA HA TERMINADO", this.$localStorageService.getIdUsuario());
              }
            }, 5000);
          });
    },
    abrirTerminos() {
      this.$refs["terminos"].show();
    },
    mostrarMensajeError(mensaje) {
      this.$swal({
        icon: "error",
        title: `${mensaje}`,
        confirmButtonText: "OK",
      });
    },
    mostrarMensajeInfo(mensaje) {
      this.$swal({
        icon: "info",
        title: `${mensaje}`,
        confirmButtonText: "OK",
      });
    },
    obtenerUbicacion() {
        navigator.geolocation.getCurrentPosition(
            response => {
                this.lat =  response.coords.latitude.toString();
                this.long = response.coords.longitude.toString();
            }, error => {
                console.log(error);
            });
     },
    guardarBitacora(evento, usuario) {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      };
      const url = this.$baseBack + "/bitacoraLogin/guardar";
          let enviarDatos= {
           canal: 24,
           evento: evento,
           navegador: this.navegador,
           sistemaOperativo: this.sistemaOperativo,
           ipOrigen: this.ipCliente,
           latitud: this.lat,
           longitud: this.long,
           idUsuario: usuario,

          }
      this.$http
          .post(url, enviarDatos,{headers: headers})
          .then((response) => {
            if (response.data.data) {
               console.log(response.data);
            }
          })
          .catch((err) => {
            console.log(err);
          })
    },
     obtenerSO(){
        if (window.navigator.userAgent.indexOf('Windows') > -1) {
         return "WINDOWS";
        } else if (window.navigator.userAgent.indexOf('Mac') > -1) {
            return "MAC";
        } else if (window.navigator.userAgent.indexOf('X11') > -1) {
           return  "UNIX";
        } else if (window.navigator.userAgent.indexOf('Linux') > -1) {
            return "LINUX";
        }else{
          return "OTRO"
        }
     },
     obtenerIp(){
     fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
            .then(({ ip }) => {
             this.ipCliente = ip;
          });
     },
     obtenerNavegador(){
       if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
         return 'CHROME';
        } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            return 'FIREFOX';
        } else if (navigator.userAgent.toLowerCase().indexOf('opera') > -1) {
           return  'OPERA';
        } else if (navigator.userAgent.toLowerCase().indexOf('MSIE') > -1) {
            return 'INTERNET EXPLORER';
        }else if (navigator.vendor ==  "Apple Computer, Inc.") {
            return 'SAFARI';
        }else{
          return "OTRO"
        }
     },
    loginCodigoContrasenia(){
      const parametros = {nombreUsuario: this.nombreUsuario, idSistema: Number(this.$idSistema)}
      this.$http
          .post(this.$apiControlAcceso +
              "codigoContrasenia", parametros
          )
          .then((respuesta) => {
            this.cargando = false;
            if (respuesta.data.data.codigo === 200) {
              if (!respuesta.data.data.error) {
                this.$router.push({
                  name: "VerificationCode",
                })
                localStorage.setItem('tiempo', respuesta.data.data.data.tiempo);
              } else {
                this.loginSistema();
              }
            }else if(respuesta.data.data.codigo === 205){
              //this.idUsuarioControl = respuesta.data.data.data.idUsuario;
              this.loginSistema();
            }else if(respuesta.data.data.codigo === 206){
              this.$swal.fire({
                icon: "info",
                title: "Aviso",
                showCancelButton: true,
                text: respuesta.data.data.mensaje,
                confirmButtonText: 'Aceptar',
                denyButtonText: `Salir`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                 this.resetPass();
                } else if (result.isDenied) {
                  this.$router.push({ name: "Login" });
                }
              });
            }
          })
          .catch(() => {
            this.$swal.fire({
              icon: "error",
              title: "Aviso",
              text: "Ocurrio un error al realizar la confirmación, contacte al administrador e intente nuevamente",
            });
          })
          .finally(() => {
            this.cargando = false;
            localStorage.setItem("idUsuarioControl",this.idUsuarioControl);
          });
    },
    resetPass() {
      this.$router.push({
        name: "ForgottenPassword"
      })
    }

    },
  beforeDestroy() {
  },
};
</script>
<style scoped>
  .formulario{
    z-index: 2;
    position: relative;
  }
  .diente{
    top: 70%;
    width: 23% !important;
    position: absolute;
    z-index: 1;
  }

  .contenedor-blanco{
  height: 99.6vh;
  width: 40vw;
  border-top-right-radius: 10vw;
  background-color:white;
  padding-top: 6%;
}


  @media screen and (min-width: 768px) and (max-width: 1000px) {
.wrapper {
    padding-right: 40px !important;
}
  .contenedor-blanco{
  height: 75vh;
  width: 80vw;
  border-radius: 10vw;
  background-color:rgba(255, 255, 255, 0.9);
  padding-top: 6%;
}
.button-sesion{
  width: 60%;
}

}

@media (max-width: 767px) {
  .contenedor-blanco{
  height: 99.6vh;
  width: 100vw;
  border-top-right-radius: 10vw;
  background-color:white;
  padding-top: 6%;
}

.button-sesion{
  width: 80%;
}
}


</style>

