<template>
  <div style="padding: 0px !important;">
    <loading
      :active.sync="loadingPage"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <b-form
      v-if="planSeleccionado != null"
      v-on:submit.prevent="abrirModalMetodoPago"
    >
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="image-container image-wrapper">
            <img
              class="plan-image"
              :src="carrito[planSeleccionado].imagenPlan"
              alt=""
            />
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-8" style="font-size: 14px">
          <p class="titulo-plan">{{ carrito[planSeleccionado].nombrePlan }}</p>
          <p>
            <strong> Vigencia: </strong>
            {{ carrito[planSeleccionado].vigenciaInicio | formatoFecha }} al
            {{ carrito[planSeleccionado].vigenciaFin | formatoFecha }}
          </p>
          <p>
            <strong>Tipo plan:</strong>
            {{ carrito[planSeleccionado].tipoValidacion }}
          </p>
          <p>
            <strong>Personas incluidas:</strong>
            {{ carrito[planSeleccionado].numeroMaximo }}
          </p>
        </div>
      </div>

      <div class="scrollable-container no-horizontal-scroll height-component mt-5 mb-4">
          <div class="mb-5" v-for="(paciente, index) in carrito[planSeleccionado].pacientes" :key="index + 1">
              <b-row class="p-0 m-0" :class="cardSeleccionado !== index ? 'linePaciente' : '' " style="height: 30px" v-b-toggle="'collapseDetailOD-'+index">
                  <b-col>
                      <div class="d-flex">
                          <p class="">{{ index + 1 }}.</p>
                          <p v-if="paciente.nombrePaciente != null && paciente.apellidoP != null && paciente.apellidoM != null && (paciente.nombrePaciente != '' || paciente.apellidoP != '' || paciente.apellidoM != '')" class="textoBold">
                          {{
                              paciente.nombrePaciente +
                              " " +
                              paciente.apellidoP +
                              " " +
                              paciente.apellidoM
                          }}
                          </p>
                          <p class="ml-2" v-else>
                              Nuevo paciente
                          </p>
                          
                      </div>
                  </b-col>
                  <b-col cols="1">
                      <div class="col-1 ml-3">
                          <!-- :rotate="rotarChevron['card-' + index] ? 180 : 0" -->
                          <b-icon v-if="cardSeleccionado === index"  @click="comprobarPropiedades(); cardSeleccionado = null" icon="chevron-up"></b-icon>
                          <b-icon v-else @click="comprobarPropiedades(); cardSeleccionado = index;" icon="chevron-down" ></b-icon>
                      </div>
                  </b-col>
              </b-row>
              <b-collapse class="mr-2 pl-2 pr-2" :id="'collapseDetailOD-'+index"  accordion="my-accordion-detalle" role="tabpanel" style="border: 1px solid #D6E7F3; border-radius: 15px;">
                  <div pl-2 pr-2>
                      <div class="row">
                          <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                              <label class="textoSemibold">
                                  Nombre(s) <span style="color: red">*</span>
                              </label>
                              <b-input-group>
                                  <b-form-input
                                  size="md"
                                      v-model="paciente.nombrePaciente"
                                      minLength="3"
                                      :placeholder="'Nombre(s)'"
                                      @keypress="$global.soloLetras($event)"
                                      @paste="$global.soloLetras($event)"
                                      :state="validatePaciente(paciente.nombrePaciente)"
                                      style="border-radius: 10px !important;"
                                      required
                                      @input="validarYEnviar(index)"
                                  >
                                  </b-form-input>
                                  <b-form-invalid-feedback
                                     v-if="validateError(paciente.nombrePaciente)"
                                  >
                                      El campo es requerido, mínimo 3 letras.
                                  </b-form-invalid-feedback>
                              </b-input-group>
                          </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <label class="textoSemibold"
                  >Apellido paterno <span style="color: red">*</span></label
                >
                <b-input-group>
                  <b-form-input
                  size="md"
                    v-model="paciente.apellidoP"
                    minLength="3"
                    placeholder="Apellido paterno *"
                    @keypress="$global.soloLetras($event)"
                    @paste="$global.soloLetras($event)"
                    :state="validatePaciente(paciente.apellidoP, paciente.touched)"
                    required
                    @input="validarYEnviar(index)"
                    style="border-radius: 10px !important;"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="
                      paciente.apellidoP != null &&
                      paciente.apellidoP.length >= 3
                    "
                  >
                    El campo es requerido, mínimo 3 letras.
                  </b-form-invalid-feedback>
                </b-input-group>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <label class="textoSemibold"
                  >Apellido materno <span style="color: red">*</span></label
                >
                <b-input-group>
                  <b-form-input
                    v-model="paciente.apellidoM"
                    minLength="3"
                    placeholder="Apellido materno *"
                    @keypress="$global.soloLetras($event)"
                    @paste="$global.soloLetras($event)"
                    :state="
                      paciente.apellidoM != null &&
                      paciente.apellidoM.length >= 3
                    "
                    required
                    @input="validarYEnviar(index)"
                    style="border-radius: 10px !important;"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="
                      paciente.apellidoM != null &&
                      paciente.apellidoM.length >= 3
                    "
                  >
                    El campo es requerido, mínimo 3 letras.
                  </b-form-invalid-feedback>
                </b-input-group>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <label class="textoSemibold"
                  >Sexo <span style="color: red">*</span></label
                >
                <b-input-group>
                  <b-form-select
                    v-model="paciente.sexo"
                    :options="listaSexo"
                    value-field="id"
                    text-field="nombre"
                    :state="paciente.sexo != null"
                    required
                    @input="validarYEnviar(index)"
                    style="border-radius: 10px !important;"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback :state="paciente.sexo != null">
                    Debe seleccionar una opción
                  </b-form-invalid-feedback>
                </b-input-group>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <label class="textoSemibold"
                  >Correo <span style="color: red">*</span></label
                >
                <div class="enLinea">
                  <b-input-group>
                    <b-form-input
                      type="email"
                      v-model="paciente.correo"
                      placeholder="Correo"
                      :state="validMail(paciente.correo)"
                      :disabled="paciente.habilitarCorreo"
                      required
                      @input="validarYEnviar(index)"
                      style="border-radius: 10px !important;"
                    ></b-form-input>
                  </b-input-group>
                  <b-form-checkbox
                    v-b-tooltip.hover
                    title="Mismo correo para los beneficiarios"
                    class="ml-2"
                    v-model="paciente.habilitarCorreo"
                    v-if="
                      index === 0 &&
                      carrito[planSeleccionado].pacientes.length > 1 &&
                      paciente.correo !== undefined &&
                      paciente.correo !== '' &&
                      paciente.correo !== null &&
                      carrito[planSeleccionado].numeroMaximo > 1
                    "
                    v-on:change="
                      seleccionarCorreoGlobal(planSeleccionado, $event)
                    "
                  >
                  </b-form-checkbox>
                </div>
                <b-form-invalid-feedback :state="validMail(paciente.correo)">
                  El campo es requerido
                </b-form-invalid-feedback>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <label class="textoSemibold"
                  >Teléfono <span style="color: red">*</span></label
                >
                <div class="enLinea">
                  <vue-phone-number-input
                    v-model="paciente.telefono"
                    minLength="12"
                    maxLength="12"
                    :translations="traslationsTxt"
                    default-country-code="MX"
                    placeholder="Ingresa número a 10 dígitos: *"
                    :disabled="paciente.habilitarTelefono"
                    @update="onUpdateTel"
                    required
                    @input="validarYEnviar(index)"
                    style="border-radius: 10px !important;"
                  />

                  <b-form-checkbox
                    v-b-tooltip.hover
                    title="Mismo teléfono para los beneficiarios"
                    class="ml-2"
                    v-model="paciente.habilitarTelefono"
                    v-if="
                      index === 0 &&
                      carrito[planSeleccionado].pacientes.length > 1 &&
                      paciente.telefono !== undefined &&
                      paciente.telefono !== '' &&
                      paciente.telefono !== null &&
                      carrito[planSeleccionado].numeroMaximo > 1
                    "
                    v-on:change="
                      seleccionarTelefonoGlobal(planSeleccionado, $event)
                    "
                  >
                  </b-form-checkbox>
                </div>
                <b-form-invalid-feedback
                  :state="
                    paciente.telefono !== undefined &&
                    paciente.telefono !== '' &&
                    paciente.telefono !== null
                  "
                >
                  El campo es requerido
                </b-form-invalid-feedback>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <label class="textoSemibold"
                  >Fecha de nacimiento <span style="color: red">*</span></label
                >
                <b-input-group>
                  <b-form-datepicker
                    name="fecha"
                    show-decade-nav
                    :required="true"
                    :state="
                      paciente.fechaNacimiento ||
                      paciente.fechaNacimiento != null
                    "
                    v-model="paciente.fechaNacimiento"
                    @input="
                      verificarPersona(paciente, carrito[planSeleccionado],index)
                    "
                    style="border-radius: 10px !important;"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback
                    :state="paciente.fechaNacimiento ? true : false"
                  >
                    Debe seleccionar una fecha
                  </b-form-invalid-feedback>
                </b-input-group>
              </div>

              <div v-if="carrito[planSeleccionado].pacientes.length > 1" class="col-sm-12 col-md-6 col-lg-6 mt-3">
                <label class="textoSemibold"
                  >Tipo de beneficiario <span style="color: red">*</span></label
                >
                <br />
                <b-input-group>
                  <b-form-select
                    v-model="paciente.tipoAsegurado"
                    :options="listaAsegurado"
                    value-field="idTipoAsegurado"
                    text-field="descripcion"
                    :state="paciente.tipoAsegurado != null"
                    v-on:change="
                      verificarTitular(
                        planSeleccionado,
                        index,
                        paciente.tipoAsegurado
                      )
                    "
                    required
                    @input="validarYEnviar(index)"
                    style="border-radius: 10px !important;"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback
                    :state="paciente.tipoAsegurado != null"
                  >
                    Debe seleccionar una opción
                  </b-form-invalid-feedback>
                  <b-form-valid-feedback
                    v-if="paciente.tipoAsegurado === 1"
                    :state="paciente.tipoAsegurado != null"
                  >
                    Titular
                  </b-form-valid-feedback>
                </b-input-group>
              </div>

              <div
                class="col-12"
                v-if="carrito[planSeleccionado].requiereVendedor"
              >
                <p>
                  <strong
                    >{{ carrito[planSeleccionado].nombreTipoVendedor }}:</strong
                  >* En caso de NO contar con la información colocar “No
                  Aplica”.
                </p>
                <div class="enLinea">
                  <b-input-group>
                    <b-form-input
                      v-model="paciente.nombreVendedor"
                      :disabled="paciente.habilitarNombreVendedor"
                    ></b-form-input>
                  </b-input-group>
                  <b-form-checkbox
                    v-b-tooltip.hover
                    title="Mismo nombre del vendedor para los beneficiarios"
                    class="ml-2"
                    v-model="paciente.habilitarNombreVendedor"
                    v-if="
                      index === 0 &&
                      carrito[planSeleccionado].pacientes.length > 1 &&
                      paciente.nombreVendedor !== undefined &&
                      paciente.nombreVendedor !== '' &&
                      paciente.nombreVendedor !== null
                    "
                    v-on:change="seleccionarNombreVendedorGlobal(index, $event)"
                  >
                  </b-form-checkbox>
                </div>
                <b-form-invalid-feedback
                  :state="
                    paciente.nombreVendedor !== undefined &&
                    paciente.nombreVendedor !== '' &&
                    paciente.nombreVendedor !== null
                  "
                >
                  El campo es requerido
                </b-form-invalid-feedback>
              </div>

            </div>
              </div>
          </b-collapse>

      </div>
      </div>

    </b-form>

    <b-modal
      v-model="modalTerminos"
      title="Términos y condiciones"
      :hide-footer="true"
    >
      <div class="decargarTerminosCondiciones">
        <b-row class="mt-4 text-right">
          <b-col>
            <b-button
              @click="downloadBase64File"
              class="DosSecundario m-1"
              size="sm"
            >
              Descargar Terminos y Condiciones
            </b-button>
          </b-col>
        </b-row>
      </div>
      <iframe
        :src="terminoCondicionesBase64"
        frameborder="0"
        height="400%"
        width="100%"
        style="height: 90vh"
        onload="document.getElementById('loadImg').style.display='none';"
        class="iframeTerminos"
      >
      </iframe>
    </b-modal>
    <b-modal
      v-model="modalContacto"
      title="Configurar contacto principal"
      :hide-footer="true"
    >
      <b-form v-on:submit.prevent="guardarContactoPrincipal()">
        <b-row>
          <b-col cols="10"> ¿El contacto principal es paciente? </b-col>
          <b-col cols="1">
            <b-form-checkbox
              v-model="isContactoPrincipalPaciente"
              switch
              style="text-align: right; margin-left: 35%"
            >
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-input-group v-if="isContactoPrincipalPaciente">
          <b-form-select
            id="selectPacientes"
            class="mt-4 mb-5"
            v-model="contactoPrincipal"
            :options="
              listaPacientes.map((paciente) => ({
                text: paciente.nombreCompleto,
                value: paciente,
              }))
            "
            placeholder="Selecciona un paciente"
            required
            :state="
              contactoPrincipal.nombreCompleto != null &&
              contactoPrincipal != ''
            "
          >
          </b-form-select>
          <b-form-invalid-feedback
            :state="contactoPrincipal.nombreCompleto && contactoPrincipal != ''"
          >
            El campo es requerido
          </b-form-invalid-feedback>
        </b-input-group>

        <div v-else class="mt-4 mb-2">
          <b-input-group>
            <b-form-input
              class="form-control-sm"
              v-model="contactoPrincipal.nombreCompleto"
              minLength="3"
              :placeholder="'Nombre completo'"
              @keypress="$global.soloLetras($event)"
              @paste="$global.soloLetras($event)"
              :state="
                contactoPrincipal.nombreCompleto != null &&
                contactoPrincipal.nombreCompleto.length >= 3
              "
              required
            >
            </b-form-input>
            <b-form-invalid-feedback
              :state="
                contactoPrincipal.nombreCompleto != null &&
                contactoPrincipal.nombreCompleto.length >= 3
              "
            >
              El campo es requerido, mínimo 3 letras.
            </b-form-invalid-feedback>
          </b-input-group>
          <b-input-group class="mt-2">
            <b-form-input
              class="form-control-sm"
              type="email"
              v-model="contactoPrincipal.correo"
              placeholder="Correo"
              :state="validMail(contactoPrincipal.correo)"
              required
            >
            </b-form-input>
            <b-form-invalid-feedback
              :state="
                contactoPrincipal.correo != null &&
                contactoPrincipal.correo.length >= 3
              "
            >
              El campo es requerido
            </b-form-invalid-feedback>
          </b-input-group>
          <div class="d-flex justify-content-start">
            <b-input-group class="mt-2">
              <vue-phone-number-input
                v-model="contactoPrincipal.telefono"
                minLength="12"
                maxLength="12"
                :translations="traslationsTxt"
                default-country-code="MX"
                placeholder="Ingresa número a 10 dígitos: *"
                @update="onUpdateTel"
                required
              />
              <b-form-invalid-feedback
                :state="
                  contactoPrincipal.telefono != null &&
                  contactoPrincipal.telefono.length >= 3
                "
              >
                El campo es requerido
              </b-form-invalid-feedback>
            </b-input-group>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            type="submit"
            variant="primary"
            class="mt-3 mr-5"
            style="width: 35%"
            :disabled="loader"
          >
            <span v-if="!loader">Guardar </span>
            <span v-else>
              <b-spinner small></b-spinner>
              Cargando...
            </span>
          </b-button>
          <b-button
            style="width: 35%"
            class="mt-3"
            variant="secondary"
            block
            :disabled="loader"
            @click="modalContacto = false"
          >
            <span v-if="!loader">Cancelar </span>
            <span v-else>
              <b-spinner small></b-spinner>
              Cargando...
            </span>
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
        v-model="blnMetodoPago"
        centered no-close-on-backdrop
        no-close-on-esc    :hide-footer="true"
        size="lg"
    >
      <template #modal-header="{}">
        <div class="h5">
          <div class="modal-title">$ Método de Pago</div>
        </div>
      </template>
      <b-form v-on:submit.prevent="guardarPacienteTemporal()">
        <b-row  class="mb-4" >
          <b-col cols="10"> ¿Cómo te gustaría pagar?</b-col>
        </b-row>
        <label class="labelsF mt-2" style="font-weight: bolder">Método de Pago</label>
        <b-input-group>
          <b-form-select
              id="idMetodoPagoStripe"
              v-model="metodoPagoSeleccionado"
              :options="
              listaMetodosPago.map((metodo) => ({
                text: metodo.metodoPago,
                value: metodo.idMetodoPagoStripe,
              }))
            "
              placeholder="Selecciona un metodo de pago"
              required
              :state="
              metodoPagoSeleccionado != null &&
              metodoPagoSeleccionado != ''
            "
          >
          </b-form-select>
          <b-form-invalid-feedback
              :state="metodoPagoSeleccionado != null && metodoPagoSeleccionado != ''"
          >
            El campo es requerido
          </b-form-invalid-feedback>
        </b-input-group>
        <div class="mt-4 mb-4" v-if="metodoPagoSeleccionado === 2"><span style="color: red">*</span>Los meses sin intereses están sujetos a disponibilidad del banco emisor de la tarjeta.</div>
        <div class="d-flex justify-content-center">
          <b-button
              type="submit"
              variant="primary"
              class="mt-3 mr-5"
              style="width: 35%"
              :disabled="loader"
          >
            <span v-if="!loader">Continuar </span>
            <span v-else>
              <b-spinner small></b-spinner>
              Cargando...
            </span>
          </b-button>
          <b-button
              style="width: 35%"
              class="mt-3"
              variant="secondary"
              block
              :disabled="loader"
              @click="blnMetodoPago = false"
          >
            <span v-if="!loader">Cancelar </span>
            <span v-else>
              <b-spinner small></b-spinner>
              Cargando...
            </span>
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "FormularioPaciente",
  components: {},
  props: {
    membresias: {
      type: Boolean,
      default: false
    },
    planSeleccionado: {
      default: 0,
      type: Number,
    },
    traerPacientes: Boolean,
  },
  data: () => ({
      cardSeleccionado:false,
    terminos: false,
    fullPage: true,
    loadingPage: false,
    contactoGuardadoExitoso: false,
    modalContacto: false,
    isContactoPrincipalPaciente: true,
    contactoPrincipal: {
      nombreCompleto: null,
      correo: null,
      telefono: null,
    },
    listaParentesco: [],
    listaAsegurado: [],
    portalMsi: null,
    listaSexo: [],
    promociones: null,
    displayList: true,
    pagoRecurrente: null,
    pagoRecurrenteOps: [
      { text: "Si", value: true },
      { text: "No", value: false },
    ],
    traslationsTxt: {
      countrySelectorLabel: "Código de país",
      countrySelectorError: "Elige un país",
      phoneNumberLabel: "Número de teléfono",
      example: "Ejemplo :",
    },
    codigoTelefono: null,
    correoGlobal: false,
    telefonoGlobal: false,
    carrito: [],
    terminoCondicionesBase64: null,
    terminosCondicionesOps: [
      { text: "Si", value: true },
      { text: "No", value: false },
    ],
    aceptaTerminos: null,
    btnPagoRecurrente: false,
    loader: false,
    formularioCompleto: [],
    datosFormulario: {
      habilitarCorreo: null,
      habilitarNombreVendedor: null,
      habilitarPagoRecurrente: null,
      habilitarTelefono: null,
      nombrePaciente: null,
      apellidoP: null,
      apellidoM: null,
      sexo: null,
      fechaNacimiento: null,
      correo: null,
      telefono: null,
      tipoAsegurado: null,
      completo: null,
      nombreVendedor: null,
    },
    listaPacientes: [],
    isFormulariosCompletos: false,
    contactoGuardado: null,
    titularStripe: null,
    modalTerminos: false,
    rotarChevron: [],
    blnMetodoPago: false,
    listaMetodosPago:[],
    metodoPagoSeleccionado:null
  }),
  watch: {
    cerrarModal() {
      this.$emit("cerrar-modal");
    },
    isContactoPrincipalPaciente() {
      this.contactoPrincipal = {
        nombreCompleto: null,
        correo: null,
        telefono: null,
      };
    },
  },
  created() {
    this.carrito = this.$session.get("carrito");
    this.traerSexo();
    this.traerMetodosPago();
    this.traerTipoAsegurado();
    this.traerDatosPortalLinea();
    this.obtenerAvisoTerminosCondiciones();
    this.btnPagoRecurrente = this.$session.get("carrito")[0].pagoRecurrente;

    this.carrito.forEach(plan => {
      if(plan.pacientes.length == 1){
        plan.pacientes[0].tipoAsegurado = 1;
      }
    });
    

    window.location.hash="no-back-button";
    window.location.hash="Again-No-back-button";
    window.onhashchange=function(){window.location.hash="no-back-button";}
  },
  mounted() {
    this.planSeleccionado = 0;
    this.carrito.forEach((plan) => {
      plan.pacientes.forEach((paciente) => {
        Object.assign(paciente, { habilitarTelefono: false });
        Object.assign(paciente, { habilitarCorreo: false });
        Object.assign(paciente, { habilitarPagoRecurrente: false });
        Object.assign(paciente, { habilitarNombreVendedor: false });
        Object.assign(paciente, { cardAbierta: false });
      });
    });
  },
  methods: {
      validarPaciente(paciente) {
      return (paciente.nombrePaciente !== undefined && paciente.nombrePaciente.length >= 3) &&
              (paciente.apellidoP !== undefined && paciente.apellidoP.length >= 3) &&
              (paciente.apellidoM !== undefined && paciente.apellidoM.length >= 3) &&
              (paciente.sexo !== undefined && paciente.sexo != null)  &&
              (paciente.fechaNacimiento !== undefined && paciente.fechaNacimiento != null)  &&
              (this.validMail(paciente.correo))  &&
              (paciente.telefono !== undefined && paciente.telefono.length == 12) &&
              (paciente.tipoAsegurado !== undefined && paciente.tipoAsegurado != null);
  },
  validarTodosLosPacientes() {
        /*   let infoPaciente = this.carrito[this.planSeleccionado].pacientes[index];
    return (infoPaciente.nombrePaciente != undefined && infoPaciente.nombrePaciente.length >= 3) && (infoPaciente.apellidoP != undefined && infoPaciente.apellidoP.length >= 3);  */
      return this.carrito.every(plan =>
      plan.pacientes.every(paciente => this.validarPaciente(paciente))
    );
  },

      validarYEnviar(index) {
    
    this.$emit('inputChange', this.validarTodosLosPacientes(index), this.carrito);
  },
   setTouched(index) {
    this.carrito[this.planSeleccionado].pacientes[index].touched = true;
  },
  validateError(infoPaciente) {
      
    return !!(infoPaciente === undefined || infoPaciente.length == 0 || infoPaciente.length < 3);
  },
  validatePaciente(infoPaciente) {
    return infoPaciente !== undefined && infoPaciente.length >= 3;
  },

    abrirTerminos() {
      this.modalTerminos = true;
    },
    generarBodyCheckoutSession() {
      let pacientesEnviar = [];
      this.carrito.forEach((element) => {
        console.log(element);
        let beneficiarios = [];
        if (element.numeroMaximo > 1) {
          const pxTitular = element.pacientes.filter(
            (elemento) => elemento.tipoAsegurado === 1
          );
          //const pxBeneficiarios = element.pacientes.filter((elemento) => elemento.tipoAsegurado === 2);
          element.pacientes.forEach((px) => {
            if (px.tipoAsegurado === 2) {
              let beneficiario = {
                idPaciente: px.idPaciente,
                nombreCompleto:
                  px.nombrePaciente + " " + px.apellidoM + " " + px.apellidoP,
                nombreVendor: px.habilitarNombreVendedor
                  ? px.nombreVendedor
                  : null,
                idTipoAsegurado: px.tipoAsegurado,
                idDefaultPrice: element.idDefaultPriceStripe,
                idDefaultPriceSubscription: element.idDefaultPriceStripeSubscription,
                idPlan: element.idPlan,
              };
              beneficiarios.push(beneficiario);
            }
          });

          let pacientePlan = {
            idPaciente: pxTitular[0].idPaciente,
            nombreCompleto:
              pxTitular[0].nombrePaciente +
              " " +
              pxTitular[0].apellidoM +
              " " +
              pxTitular[0].apellidoP,
            nombreVendor: pxTitular[0].nombreVendedor,
            idTipoAsegurado: pxTitular[0].tipoAsegurado,
            idDefaultPrice: element.idDefaultPriceStripe,
            idDefaultPriceSubscription: element.idDefaultPriceStripeSubscription,
            idPlan: element.idPlan,
            precioPlan: element.precio,
            precioCotizado: element.precioSinComision,
            precioCotizadoIva: element.precioCotizadoIva,
            montoComision: element.montoComision,
            comision : element.comision,
            montofijoTransaccion: element.montofijoTransaccion,
            beneficiarios: beneficiarios,
          };
          pacientesEnviar.push(pacientePlan);
        } else {
          const px = element.pacientes[0];
          let pacientePlan = {
            idPaciente: px.idPaciente,
            nombreCompleto:
              px.nombrePaciente + " " + px.apellidoM + " " + px.apellidoP,
            nombreVendor: px.habilitarNombreVendedor ? px.nombreVendedor : null,
            idTipoAsegurado: px.tipoAsegurado,
            idDefaultPrice: element.idDefaultPriceStripe,
            idDefaultPriceSubscription: element.idDefaultPriceStripeSubscription,
            idPlan: element.idPlan,
            precioPlan: element.precio,
            precioCotizado: element.precioSinComision,
            precioCotizadoIva: element.precioCotizadoIva,
            montoComision: element.montoComision,
            comision : element.comision,
            montofijoTransaccion: element.montofijoTransaccion,
            beneficiarios: [],
          };
          pacientesEnviar.push(pacientePlan);
        }
      });
      console.log("**********")
      console.log(pacientesEnviar)
      let objetoEnviar = {
        pacientesPlan: pacientesEnviar,
        titularStripe: this.titularStripe,
        idMsiPortal: 1,
        aceptoTerminos: this.terminos,
        idMetodoPagoStripe: this.metodoPagoSeleccionado
      };
      return objetoEnviar;
    },

    async crearCheckoutSession() {

      const url = this.$baseBack + "/api/integraciones/stripe/generar/pago";
      await this.$http
        .post(url, this.generarBodyCheckoutSession())
        .then((response) => {
          if (response.status == 200) {
            window.location.href = response.data.data.urlPago;
          }
        })
        .catch((err) => {
          console.error(err);
          this.mostrarMensajeError(err);
        });
    },

    async mostrarModalContacto() {
      await this.comprobarPropiedades();
      await this.generarListaPacientes();

      const indexPacienteSinTitular = this.carrito.findIndex((element) => {
        return (
          element.numeroMaximo > 1 &&
          !element.pacientes.some((paciente) => paciente.tipoAsegurado === 1)
        );
      });

      if (indexPacienteSinTitular !== -1) {
        this.$swal({
          icon: "error",
          title:
            "Por favor seleccione un paciente titular para el plan " +
            (indexPacienteSinTitular + 1) +
            " " +
            this.carrito[indexPacienteSinTitular].nombrePlan,
          confirmButtonText: "Aceptar",
          showCancelButton: false,
        });
        return;
      }

      if (!this.isFormulariosCompletos) {
        this.$swal({
          icon: "error",
          title:
            "Por favor llene completamente los formularios de los pacientes",
          confirmButtonText: "Aceptar",
          showCancelButton: false,
        });
      } else {
        this.modalContacto = true;
      }
    },

    async guardarContactoPrincipal() {
      this.loader = true;
      await this.$http
        .post(
          this.$api + "integraciones/stripe/guardar/titular",
          this.contactoPrincipal
        )
        .then((response) => {
          if (response.data.data) {
            this.contactoGuardado = response.data.data;
            this.titularStripe = response.data.data;
            this.contactoGuardadoExitoso = true;
            this.loader = false;
          }
        })
        .catch((err) => {
          this.errorTraerDatos(err);
          this.contactoGuardadoExitoso = false;
          this.loader = false;
        })
        .finally(() => {
          this.loader = false;
          this.modalContacto = false;
        });
    },
    async comprobarPropiedades() {
      this.carrito.forEach(plan => {
        plan.pacientes.forEach((paciente) => {
          // si el plan requiere vendedor se agrega la propiedad nombreVendedor al objeto de ejemplo, para posteriormente compararlo con el objeto del formulario
        if (plan.requiereVendedor) {
          this.datosFormulario.nombreVendedor = null;
        }else{
          delete this.datosFormulario.nombreVendedor;
          delete paciente.nombreVendedor;
        }

        //se obtiene un ejemplo del objeto completo
        const clavesFormularioCompleto = Object.keys(this.datosFormulario);

        //se comprueba si algún valor del array va vacío
        const todosLosValoresNoVacios = Object.values(
          paciente
        ).every((valor) => !!valor || valor === false);

        paciente.completo = null;

        const clavesFormularioPaciente = Object.keys(
          paciente
        );
 
        //se compara si el formulario actual tiene los mismos campos que clavesFormularioCompleto
        // y también verifica que todos los valores no estén vacíos
        if (
          clavesFormularioPaciente.length >= clavesFormularioCompleto.length &&
          todosLosValoresNoVacios
        ) {
          paciente.completo = true;
        } else {
          paciente.completo = false;
        }
      });
      });

      this.$forceUpdate();
    },

    generarListaPacientes() {
     let todosCompletos = true;
      this.carrito.forEach((plan) => {
           plan.pacientes.forEach((paciente) => {
             if (paciente.completo === false) {
               todosCompletos = false;
             }
           }
      )});
      if (todosCompletos) {
        this.isFormulariosCompletos = true;
        this.listaPacientes = this.carrito.reduce((result, plan) => {
          plan.pacientes.forEach((paciente) => {
            const nombreCompleto =
              paciente.nombrePaciente +
              " " +
              paciente.apellidoP +
              " " +
              paciente.apellidoM;
            const correo = paciente.correo;
            const telefono = paciente.telefono;

            result.push({
              nombreCompleto,
              correo,
              telefono,
            });
          });
          return result;
        }, []);
      }
      this.isFormulariosCompletos = todosCompletos;
      this.$forceUpdate();
    },

    validMail(email) {
      var expr =
        /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      return expr.test(email);
    },

    verificarEdades() {
      let errorEncontrado = false;
      this.carrito.forEach(plan => {
        const mayoresDe18 = plan.pacientes.filter(paciente => this.compararEdades(paciente));
        
        if (mayoresDe18.length > 2) {
          console.error(`Error: En el plan '${plan.nombrePlan}' hay más de dos pacientes mayores de 18 años.`);
          errorEncontrado = true;
        }
      });

      if (!errorEncontrado) {
        console.log("La verificación ha sido completada correctamente.");
        return true;
      } else {
        console.error("Error: Se encontró al menos un plan con más de dos pacientes mayores de 18 años.");
        return false;
      }


    },

    compararEdades(paciente){
      const hoy = this.$moment();
      const fechaNacimiento = this.$moment(paciente.fechaNacimiento, "YYYY-MM-DD");
      const edad = hoy.diff(fechaNacimiento, 'years');
      return edad >= 18;
    },

    verificarPersona(persona, { idPlan }, index) {
      this.loadingPage = true;

      const verificarEdad = this.verificarEdades();
     
      console.log("this.membresias",this.membresias)

      if (new Date(persona.fechaNacimiento) > new Date()) {
        this.$swal({
          icon: "warning",
          title: "La fecha de nacimiento no puede ser superior a hoy",
          confirmButtonText: "Entendido",
        });
        this.loadingPage = false;
        setTimeout(() => {
          persona.fechaNacimiento = null;
        }, 200);
        return false;
      }else if(!verificarEdad && this.membresias){ 
        this.$swal({
          icon: "error",
          title: "Únicamente es posible agregar dos mayores de edad por plan",
          confirmButtonText: "Entendido",
        });
        this.loadingPage = false;
        setTimeout(() => {
          persona.fechaNacimiento = null;
        }, 200);
        return false;
      }else if (
        !persona.nombrePaciente ||
        persona.nombrePaciente === "" ||
        !persona.apellidoP ||
        persona.apellidoP === "" ||
        !persona.apellidoM ||
        persona.apellidoM === "" || 
        !persona.correo ||
        persona.correo === "" ||
        !persona.telefono ||
        persona.telefono === "" ||
        persona.telefono.length < 12 ||
        !this.validMail(persona.correo) ||
        !persona.sexo ||
        persona.sexo === ""
        
      ) {
        this.$swal({
          icon: "warning",
          title: "Datos faltantes",
          text: "Por favor llene antes el nombre, apellidos, sexo, correo y teléfono",
          confirmButtonText: "Entendido",
        });
        this.loadingPage = false;
        setTimeout(() => {
          persona.fechaNacimiento = null;
        }, 200);
        return false;
      } else {
        this.displayList = false;
        const dtoPersona = {};
        dtoPersona.nombre = persona.nombrePaciente.trim().toUpperCase();
        dtoPersona.apaterno = persona.apellidoP.trim().toUpperCase();
        dtoPersona.amaterno = persona.apellidoM.trim().toUpperCase();
        dtoPersona.fechaNacimiento = this.$moment(
          persona.fechaNacimiento
        ).format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        dtoPersona.idPlan = idPlan;

        this.$http
          .post(this.$api + "plan/findPersonaCuentaConPlanBase", dtoPersona)
          .then((response) => {
            const carrito = this.$session.get("carrito");
            if (carrito[this.planSeleccionado].esUpgrade) {
              if (response.data.data.tienePlan === false) {
                this.$swal({
                  icon: "warning",
                  title:
                    "El paciente que estas ingresando no cuenta con ningun plan base, solo se estarán adquiriendo los modulos de éste plan",
                  confirmButtonText: "OK",
                });
              }
            }
            if (response.data.data.tienePlan === true) {
              this.$swal({
                icon: "warning",
                title:
                  "El paciente que estas ingresando, ya cuenta con el plan base seleccionado vigente",
                confirmButtonText: "OK",
              });
            }

            if (response.data.data.existe.length > 0) {
              response.data.data.existe.forEach((e) => {
                if (e.tipoContacto === 2) {
                  persona.telefono = e.conctacto;
                }
                if (e.tipoContacto === 3) {
                  persona.correo = e.conctacto;
                }
                persona.idPaciente = e.idPaciente;
              });
            } else {
              this.guardarPacientes(persona);
            }
          })
          .catch((e) => {
            console.info(e);
            this.displayList = true;
          })
          .finally(() => {
            setTimeout(() => {
              this.loadingPage = false;
            }, 500);

            this.displayList = true;
          });
      }
      this.validarYEnviar(index);
    },

   
    traerSexo() {
      let url = this.$api + "catalogos/listaSexo";
      this.$http
        .post(url)
        .then((response) => {
          if (response.data.data) {
            response.data.data.unshift({
              id: null,
              nombre: "SEXO: *",
            });

            this.listaSexo = response.data.data;
          }
        })
        .catch((err) => {
          this.errorTraerDatos(err);
        });
    },
    traerMetodosPago() {
      let url = this.$api + "catalogos/obtenerMetodosPago";
      this.$http
          .post(url)
          .then((response) => {
            if (response.data.data) {
              this.listaMetodosPago = response.data.data;
            }
          })
          .catch((err) => {
            this.errorTraerDatos(err);
          });
    },
    traerTipoAsegurado() {
      const url = this.$api + "catalogos/planpaciente/tipoAsegurado";
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.data) {
            response.data.data.unshift({
              idTipoAsegurado: null,
              descripcion: "TIPO DE BENEFICIARIO: *",
            });

            this.listaAsegurado = response.data.data;
          }
        })
        .catch((err) => {
          this.errorTraerDatos(err);
        });
    },
    seleccionarCorreoGlobal(indicePlan, $event) {
      if ($event) {
        /*this.correoGlobal = true;
          this.carrito.forEach((plan) => {
            plan.pacientes.forEach(paciente => {
               this.$set(paciente, 'correo',plan.pacientes[0].correo)
              paciente.correo = plan.pacientes[0].correo;
            })
          });*/
        this.carrito[indicePlan].pacientes.forEach((paciente, index) => {
          if (index !== 0) {
            this.$set(
              paciente,
              "correo",
              this.carrito[indicePlan].pacientes[0].correo
            );
            paciente.correo = this.carrito[indicePlan].pacientes[0].correo;
          }
          paciente.habilitarCorreo = true;
        });
      } else {
        /*this.correoGlobal = false;
          this.carrito.forEach((plan, i) => {
            plan.pacientes.forEach((paciente, index) => {
              if(index !== 0){
                 this.$set(paciente, 'correo',undefined)
                 this.carrito[i].pacientes[index].correo = undefined;
                 paciente.correo = undefined;
              }
            })
          });*/
        this.carrito[indicePlan].pacientes.forEach((paciente, index) => {
          if (index !== 0) {
            this.$set(paciente, "correo", undefined);
            paciente.correo = undefined;
          }
          paciente.habilitarCorreo = false;
        });
      }
    },
    seleccionarTelefonoGlobal(indicePlan, $event) {
      if ($event) {
        /*this.telefonoGlobal = true;
          this.carrito.forEach((plan) => {
            plan.pacientes.forEach(paciente => {
              this.$set(paciente, 'telefono',plan.pacientes[0].telefono)
              paciente.telefono = plan.pacientes[0].telefono;
            })
          });*/
        this.carrito[indicePlan].pacientes.forEach((paciente, index) => {
          if (index !== 0) {
            this.$set(
              paciente,
              "telefono",
              this.carrito[indicePlan].pacientes[0].telefono
            );
            paciente.telefono = this.carrito[indicePlan].pacientes[0].telefono;
          }
          paciente.habilitarTelefono = true;
        });
      } else {
        /*this.telefonoGlobal = false;
          this.carrito.forEach((plan) => {
            plan.pacientes.forEach((paciente, index) => {
              if(index !== 0){
                this.$set(paciente,'telefono',undefined)
                 paciente.telefono = undefined;
              }
            })
          });*/
        this.carrito[indicePlan].pacientes.forEach((paciente, index) => {
          if (index !== 0) {
            this.$set(paciente, "telefono", undefined);
            paciente.telefono = undefined;
          }
          paciente.habilitarTelefono = false;
        });
      }
    },
    seleccionarPagoRecurrenteGlobal(indicePlan, $event) {
      if ($event) {
        this.carrito[indicePlan].pacientes.forEach((paciente, index) => {
          if (index !== 0) {
            this.$set(
              paciente,
              "pagoRecurrente",
              this.carrito[indicePlan].pacientes[0].pagoRecurrente
            );
            paciente.pagoRecurrente =
              this.carrito[indicePlan].pacientes[0].pagoRecurrente;
          }
          paciente.habilitarPagoRecurrente = true;
        });
      } else {
        this.carrito[indicePlan].pacientes.forEach((paciente, index) => {
          if (index !== 0) {
            this.$set(paciente, "pagoRecurrente", undefined);
            paciente.pagoRecurrente = undefined;
          }
          paciente.habilitarPagoRecurrente = false;
        });
      }
    },
    seleccionarNombreVendedorGlobal(indicePlan, $event) {
      if ($event) {
        this.carrito[indicePlan].pacientes.forEach((paciente, index) => {
          if (index !== 0) {
            this.$set(
              paciente,
              "nombreVendedor",
              this.carrito[indicePlan].pacientes[0].nombreVendedor
            );
            paciente.nombreVendedor =
              this.carrito[indicePlan].pacientes[0].nombreVendedor;
          }
          paciente.habilitarNombreVendedor = true;
        });
      } else {
        this.carrito[indicePlan].pacientes.forEach((paciente, index) => {
          if (index !== 0) {
            this.$set(paciente, "nombreVendedor", undefined);
            paciente.nombreVendedor = undefined;
          }
          paciente.habilitarNombreVendedor = false;
        });
      }
    },
    onUpdateTel(payload) {
      if (payload.countryCallingCode != undefined) {
        this.codigoTelefono = payload.countryCallingCode;
      }
    },
    verificarTitular(indicePlan, indicePAciente, tipoAsegurado) {
      if (this.carrito[indicePlan].numeroMaximo == 1) {
        return;
      }

      let contador = 0;
      if (tipoAsegurado === 1) {
        contador = this.carrito[indicePlan].pacientes.filter(
          (p) => p.tipoAsegurado === 1
        ).length;
      }

      if (contador > 1) {
        this.$swal({
          icon: "question",
          title: "¿Quiere quitar el antiguo titular?",
          confirmButtonText: "Aceptar",
          showCancelButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then((aceptar) => {
          if (aceptar.value) {
            this.carrito[indicePlan].pacientes.forEach((px, index) => {
              if (index !== indicePAciente) {
                this.carrito[indicePlan].pacientes[index].tipoAsegurado = 2;
              }
            });
          } else {
            this.carrito[indicePlan].pacientes[
              indicePAciente
            ].tipoAsegurado = 2;
          }
        });
      }
    },
    async guardarPacientes(persona) {
      const data = {
        nombre: persona.nombrePaciente,
        apelleidoPaterno: persona.apellidoP,
        apellidoMaterno: persona.apellidoM,
        sexo: persona.sexo,
        fechaNacimiento: this.$moment(persona.fechaNacimiento).format(
          "YYYY-MM-DD[T]HH:mm:ss[Z]"
        ),
        correo: persona.correo,
        telefono: persona.telefono,
        tipoAsegurado: persona.tipoAsegurado,
        tipoBeneficiario: null,
      };
      persona.idPaciente = await this.$http
        .post(this.$api + "plan/guardarPaciente", data)
        .then((response) => response.data.data)
        .finally(() => {
          this.loader = false;
        });
    },
    async guardarPacienteTemporal() { 
      if (!this.terminos) {
        this.$swal({
          icon: "error",
          title:
            "Para continuar, por favor acepte los términos y condiciones establecidos para la compra de planes con dentalia",
          confirmButtonText: "Aceptar",
          showCancelButton: false,
        });
        return;
      }
      this.loader = true;
      for (let plan of this.carrito) {
        for (let paciente of plan.pacientes.filter((p) => !p.idPaciente)) {
          this.guardarPacientes(paciente);
        }
      }

      this.$session.set("carrito", this.carrito);
      this.$session.set("promocion", this.promociones);
      this.$session.set("idMsiSeleccionado", this.promociones);
      this.$session.set("pagoRecurrente", this.pagoRecurrente);
      this.$session.set("aceptaTerminos", this.aceptaTerminos);
      this.crearCheckoutSession();
    },
    traerDatosPortalLinea() {
      this.idEmpresa = this.$localStorageService.getIdEmpresa();
      const datos = {
        idEmpresa: this.idEmpresa
      }

      const url = this.$api + "empresas/traerPortal/linea";
      return this.$http
        .post(url, datos)
        .then((response) => {
          this.portalMsi = response.data.data.mesesSinInteres;
        })
        .catch((err) => {
          this.errorTraerDatos(err);
        })
        .finally(() => {});
    },
    obtenerAvisoTerminosCondiciones() {
      const datos = {
        idAvisoSistema: 16
      }
      const url = this.$baseBack + "/api/erp/obtenerAvisoSistema";
      this.$http
        .post(url, datos)
        .then((response) => {
          if (response.data.data !== null) {
            this.terminoCondicionesBase64 =
              "data:application/pdf;base64, " + response.data.data;
          }
        })
        .catch((err) => {
          console.info(err);
        });
    },
    downloadBase64File() {
      const linkSource = this.terminoCondicionesBase64;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "Terminos y Condiciones";
      downloadLink.click();
      this.loaderDescargar = false;
      this.$swal.fire({
        icon: "info",
        title: "Aviso",
        text: "Se descargo el archivo correctamente",
      });
    },
    rotar(id, index) {
      let idChevron = id + index;
      this.$set(this.rotarChevron, idChevron, !this.rotarChevron[idChevron]);
    },
    async abrirModalMetodoPago(){
      this.blnMetodoPago = true
    }
  },
  notifications: {
    errorTraerDatos: {
      type: "error",
      title: "Error de servicio",
      message: "Error al traer los datos",
    },
  },
};
</script>

<style scoped>
.height-component{
  min-height: 56vh;
}

.scrollable-container {
  overflow-y: auto; 
  max-height: 45vh; 
}
  .linePaciente{
      border-bottom: 1px solid #D6E7F3;
  }

   @media (max-width: 768px) {
    .height-component{
      min-height: 20vh;
      margin-bottom: 20%;
    }

    .scrollable-container {
      overflow-y: auto; 
      max-height: 70vh; 
    }

  }
.decargarTerminosCondiciones {
  display: none !important;
}
@media only screen and (max-width: 600px) {
  .decargarTerminosCondiciones {
    display: block !important;
  }
  .iframeTerminos {
    display: none !important;
  }
}

.boton-agenda-inv {
  border-radius: 50rem;
  color: var(--color-icono);
  background-color: white;
  border-color: #fff;
  box-shadow: 2px 3px 4px #c4c4c4;
  padding: 1%;
}

.boton-agenda-inv:active {
  border-color: var(--color-icono);
}


.icon-small {
  font-size: 10%; /* Ajusta el tamaño del icono según tus necesidades */
}
.modal-title {
  color: #044169 !important;
  font-weight: bolder !important;;
}
</style>

