<template>
    <div class=" main-container height-component" >
      <loading
          :active.sync="loadingPage"
          :can-cancel="false"
          :is-full-page="true"
      ></loading>
    <div class="scrollable-container-listado no-horizontal-scroll" >
      
      <div class="p-3 pr-4 pl-4" v-if="windowWidth > 768">
        <p v-if="!finCompra" class="text-subtitulos">Tu carrito</p>
        <div v-else class="d-flex justify-content-between">
            <p  class="text-subtitulos">Tu compra</p>
           <b-button
                    class="boton-agenda-inv d-flex align-items-center justify-content-center"
                    style="left: 50px; width: 30px; height: 30px"
                    variant="primary"
                    @click="guardarCertificados()"
                  >
                    <em
                      style="height: 20px;"
                      v-b-tooltip.hover
                      title="Descargar todos los certificados"
                      class="download_all_svg icon-small"
                    ></em>
                  </b-button>
        </div>
        <p v-if="!finCompra">Navegue entre cada elemento para ingresar a los pacientes</p>
        <p v-else>Navegue entre cada elemento para descargar los certificados de forma individual</p>
        <div>
        <div v-for="(plan, index) in carritoPlanes" v-bind:key="index">
            <div class="d-flex justify-content-between align-items-center row planHover pl-2 pr-2"
            :class="{'estiloPlanSeleccionado': planSeleccionado == index}" 
            style="font-weight: 600; font-size: .9em; min-height: 40px;" 
            @click="seleccionarPlan(index)">
            <div class="d-flex col-9 p-0">
                <p class="mr-1 p-0 m-0">{{ index + 1 }}.</p>
                <p class="p-0 m-0">{{ plan.nombrePlan }}</p>
            </div>
            <div class="col-1 p-0" v-if="!finCompra">
                <p class="p-0 m-0">{{ plan.precio | moneda }}</p>
            </div>
            <div class="col-1">
              <b-icon v-if="plan.planDesplegado == true" v-b-toggle="'paciente-collapse-'+index" icon="chevron-up" @click="desplegarPlan(index)"></b-icon>
              <b-icon v-else v-b-toggle="'paciente-collapse-'+index" icon="chevron-down" @click="desplegarPlan(index)"></b-icon>
            </div>
            </div>
            
            <b-collapse :id="'paciente-collapse-'+index" visible>

                <div v-if="finCompra">
                    <div v-for="(certificado, index) in plan.certificadosPacientes" v-bind:key="index">
                        <ul>
                            <li  style="color: #969BA6;">
                                <p>{{certificado.nombrePaciente}}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else>
                    <div v-for="(paciente, index) in plan.pacientes" v-bind:key="index">
                      <ul>
                        <li v-if="paciente.nombrePaciente != null && paciente.apellidoP != null && paciente.apellidoM != null && (paciente.nombrePaciente != '' || paciente.apellidoP != '' || paciente.apellidoM != '')">
                          <p>{{ paciente.nombrePaciente + ' ' + paciente.apellidoP + ' ' + paciente.apellidoM }}</p>
                        </li>
                        <li v-else style="color: #BBBBBB;">
                          <p style="color: #BBBBBB;">Nuevo paciente</p>
                        </li>
                      </ul>
                    </div>
                </div>
            </b-collapse>
          <div class="mt-3 mb-4" style="text-align: center" v-if="finCompra && membresiaTicket !== null">
            <b-button href="https://agenda.dentalia.com/?utm_campaign=Membres%C3%ADa%20dentalia&utm_source=Membresia%20dentalia&utm_medium=2024%20Agosto&utm_term=Campa%c3%b1a&utm_content=Membresia%20dentalia" target="_blank" variant="primary" class="botones"
            >Agenda tu cita</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="button-container" v-if="finCompra == false">
      
      <b-button variant="primary" class="text-center" style="width: 60%;" @click="abrirMedioContacto()" :disabled="!formularioValido">
        Continuar
      </b-button>
      <p class="text-center pt-2" style="font-size: .8em;">
        Al continuar acepto los <a><u style="color: #4B94C2;" @click="modalTerminos = true">términos y condiciones</u></a> establecidos para la compra de planes
      </p>
    </div>
    </div>

    <b-modal
      v-model="modalTerminos"
      title="Términos y condiciones"
      :hide-footer="true"
    >
      <iframe
        :src="terminoCondicionesBase64"
        frameborder="0"
        height="400%"
        width="100%"
        style="height: 90vh"
        onload="document.getElementById('loadImg').style.display='none';"
        class="iframeTerminos"
      >
      </iframe>
    </b-modal>

    <b-modal
      v-model="modalPago"
      title="Tipo de pago"
      :hide-footer="true"
      size="xs"
      no-close-on-backdrop>
      <div class="mt-2">
        <p style="font-weight: .8em;">Seleccione un tipo de pago</p>
        <label class="labelsF" style="font-weight: bolder">Método de pago</label>
                  <b-input-group style="width: 80%;">
                    <b-form-select
                        size="sm"
                        id="idMetodoPagoStripe"
                        v-model="metodoPagoSeleccionado"
                        :options="
                        listaMetodosPago.map((metodo) => ({
                          text: metodo.metodoPago,
                          value: metodo.idMetodoPagoStripe,
                        }))
                      "
                        placeholder="Selecciona un metodo de pago"
                        required
                        :state="
                        metodoPagoSeleccionado != null &&
                        metodoPagoSeleccionado != ''
                      "
                    >
                    </b-form-select>
                    <b-form-invalid-feedback
                        :state="metodoPagoSeleccionado != null && metodoPagoSeleccionado != ''"
                    >
                      El campo es requerido
                    </b-form-invalid-feedback>
                  </b-input-group>
        <p class="mt-3" v-if="metodoPagoSeleccionado == 2" style="font-size: .8em"><span style="color: red">*</span>Los meses sin intereses están sujetos a disponibilidad del banco emisor de la tarjeta</p>
      </div>

      <div class="mt-3 mb-2 d-flex justify-content-center" :class="{'mt-5': metodoPagoSeleccionado == 1}">
      <div class="d-flex justify-content-between buttonWidth" style="width: 60%;">
        <b-button @click="guardarPacienteTemporal()" variant="info" style="min-width: 130px;" :disabled="metodoPagoSeleccionado == null">Pasar al pago</b-button>
        <b-button variant="outline-secondary" style="min-width: 130px;" @click="modalPago = false">Cancelar</b-button>
          </div>
        </div>
    </b-modal>
    <b-modal
      v-model="modalMedioContacto"
      title="Medio de contacto"
      :hide-footer="true"
      size="lg"
      no-close-on-backdrop
    >
      <div style="font-size: .95em;" v-if="!nuevoContactoPrincipal">
        <p>Seleccione uno de los pacientes registrados como contacto principal o <a><u style="color: #4B94C2;" @click="establecerContactoPrincipal(true)">cree uno nuevo</u></a></p>
        
        <b-row v-if="windowWidth > 768" class="ml-1" style="min-height: 60vh;">
            <b-col cols="5" class="pt-3 scrollable-container-pacientes no-horizontal-scroll" style="border: 1px solid #E3E3E3; border-radius: 15px;">
                <div class="mb-3" v-for="(plan, indexPlan) in carritoPlanes" v-bind:key="indexPlan">
                    <div class="d-flex" style="font-weight: 500;">
                        <p class="mr-1">{{ indexPlan + 1 }}.</p>
                        <p>{{ plan.nombrePlan }}</p>
                    </div>
                    <div v-for="(paciente, indexPx) in plan.pacientes" v-bind:key="indexPx" >
                        <ul :class="{'estiloPacienteSeleccionado': pacienteSeleccionado == indexPx && planSeleccionado == indexPlan}" style="margin: 1%;">
                            <li>
                                <p class="planHover" @click="seleccionarContactoPrincipal(indexPx, indexPlan , paciente.idPaciente)" style="margin: 1%;">{{ paciente.nombrePaciente + ' ' + paciente.apellidoP + ' ' + paciente.apellidoM }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </b-col>
            <b-col>
                <div v-if="pacienteSeleccionado != null">
                    <p class="mt-3" style="color: #70C0C5; font-weight: 500;">Contacto principal</p>
                <div class="mt-4">
                    <p style="font-weight: 500;">{{ contactoPrincipal.nombreCompleto }}</p>
                    <div class="d-flex">
                        <p class="mr-1" style="font-weight: 500;">Correo: </p>
                        <p>{{ contactoPrincipal.correo }}</p>
                    </div>
                    <div class="d-flex">
                        <p class="mr-1" style="font-weight: 500;">Teléfono: </p>
                        <p>{{ contactoPrincipal.telefono }}</p>
                    </div>
                    
                </div>
                </div>
            </b-col>
        </b-row>
        <div v-else style="min-height: 16vh;">
          <b-form-select size="sm" v-model="planSeleccionado" :options="optionsWithIndex" value-field="index"
                        text-field="name">
                        </b-form-select>

          <div v-for="(paciente, indexPx) in carritoPlanes[planSeleccionado].pacientes" v-bind:key="indexPx" >
            <ul :class="{'estiloPacienteSeleccionado': pacienteSeleccionado == indexPx}" style="margin: 1%;">
              <li>
                <p class="planHover" @click="seleccionarContactoPrincipal(indexPx, planSeleccionado, paciente.idPaciente)" style="margin: 1%;">{{ paciente.nombrePaciente + ' ' + paciente.apellidoP + ' ' + paciente.apellidoM }}</p>
              </li>
            </ul>
          </div>

          <div v-if="pacienteSeleccionado != null">
                    <p class="mt-3" style="color: #70C0C5; font-weight: 500;">Contacto principal</p>
                <div class="mt-4">
                    <p style="font-weight: 500;">{{ contactoPrincipal.nombreCompleto }}</p>
                    <div class="d-flex">
                        <p class="mr-1" style="font-weight: 500;">Correo: </p>
                        <p>{{ contactoPrincipal.correo }}</p>
                    </div>
                    <div class="d-flex">
                        <p class="mr-1" style="font-weight: 500;">Teléfono: </p>
                        <p>{{ contactoPrincipal.telefono }}</p>
                    </div>
                    
                </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div @click="establecerContactoPrincipal(false)">
            <a class="d-flex">
                <em class="boton_regresar_svg mr-1" ></em>
                <p class="m-0 p-0" style="color: #838383; font-size: .9em">Regresar</p>
            </a>
        </div>
  
            <p class="mt-5" style="color: #70C0C5; font-weight: 500;">Crear nuevo contacto</p>
            <b-row class="mt-4" style="margin-bottom: 14%;">
                <b-col sm="12" md="12" lg="6">
                  <label class="labelsF mt-2" style="font-weight: bolder">Nombre  <span style="color: red">*</span></label>
                    <b-input-group class=" mb-4 pr-4">
                        <b-form-input
                            class="form-control"
                            style="border-radius: 10px !important;"
                            v-model="contactoPrincipal.nombreCompleto"
                            minLength="3"
                            :placeholder="'Nombre completo'"
                            @keypress="$global.soloLetras($event)"
                            @paste="$global.soloLetras($event)"
                            :state="
                            contactoPrincipal.nombreCompleto != null &&
                            contactoPrincipal.nombreCompleto.length >= 3
                            "
                            required
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            :state="
                            contactoPrincipal.nombreCompleto != null &&
                            contactoPrincipal.nombreCompleto.length >= 3
                            "
                        >
                            El campo es requerido, mínimo 3 letras.
                        </b-form-invalid-feedback>
                        </b-input-group>
                </b-col>
              <b-col sm="12" md="12" lg="6">
                <label class="labelsF mt-2" style="font-weight: bolder">Apellido Paterno  <span style="color: red">*</span></label>
                <b-input-group class=" mb-4">
                  <b-form-input
                      class="form-control"
                      style="border-radius: 10px !important;"
                      v-model="contactoPrincipal.apellidoPaterno"
                      minLength="3"
                      :placeholder="'Apellido Paterno'"
                      @keypress="$global.soloLetras($event)"
                      @paste="$global.soloLetras($event)"
                      :state="
                            contactoPrincipal.apellidoPaterno != null &&
                            contactoPrincipal.apellidoPaterno.length >= 3
                            "
                      required
                  >
                  </b-form-input>
                  <b-form-invalid-feedback
                      :state="
                            contactoPrincipal.apellidoPaterno != null &&
                            contactoPrincipal.apellidoPaterno.length >= 3
                            "
                  >
                    El campo es requerido, mínimo 3 letras.
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-col>
              <b-col sm="12" md="12" lg="6">
                <label class="labelsF mt-2" style="font-weight: bolder">Apellido Materno  <span style="color: red">*</span></label>
                <b-input-group class=" mb-4 pr-4">
                  <b-form-input
                      class="form-control"
                      style="border-radius: 10px !important;"
                      v-model="contactoPrincipal.apellidoMaterno"
                      minLength="3"
                      :placeholder="'Apellido Materno'"
                      @keypress="$global.soloLetras($event)"
                      @paste="$global.soloLetras($event)"
                      :state="
                            contactoPrincipal.apellidoMaterno != null &&
                            contactoPrincipal.apellidoMaterno.length >= 3
                            "
                      required
                  >
                  </b-form-input>
                  <b-form-invalid-feedback
                      :state="
                            contactoPrincipal.apellidoMaterno != null &&
                            contactoPrincipal.apellidoMaterno.length >= 3
                            "
                  >
                    El campo es requerido, mínimo 3 letras.
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-col>
                <b-col sm="12" md="12" lg="6" >
                  <label class="labelsF mt-2" style="font-weight: bolder">Correo  <span style="color: red">*</span></label>
                    <b-input-group>
                        <b-form-input
                            style="border-radius: 10px !important;"
                            type="email"
                            v-model="contactoPrincipal.correo"
                            placeholder="Correo"
                            :state="validMail(contactoPrincipal.correo)"
                            required
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            :state="
                            contactoPrincipal.correo != null &&
                            contactoPrincipal.correo.length >= 3
                            "
                        >
                            El campo es requerido
                        </b-form-invalid-feedback>
                        </b-input-group>
                </b-col>
                <b-col sm="12" md="12" lg="6" class="pr-4">
                  <label class="labelsF mt-5" style="font-weight: bolder">Teléfono  <span style="color: red">*</span></label>
                    <b-input-group class="">
                        <vue-phone-number-input
                        style="width: 96%; border-radius: 10px !important;"
                        v-model="contactoPrincipal.telefono"
                        minLength="12"
                        maxLength="12"
                        :translations="traslationsTxt"
                        default-country-code="MX"
                        placeholder="Ingresa número a 10 dígitos: *"
                        @update="onUpdateTel"
                        required
                        />
                        <b-form-invalid-feedback
                        :state="
                            contactoPrincipal.telefono != null &&
                            contactoPrincipal.telefono.length >= 3
                        "
                        >
                        El campo es requerido
                        </b-form-invalid-feedback>
                    </b-input-group>
                </b-col>
              <b-col sm="12" md="12" lg="6">
                <label class="labelsF mt-5" style="font-weight: bolder">Sexo  <span style="color: red">*</span></label>
                <b-input-group>
                  <b-form-select
                      v-model="contactoPrincipal.sexo"
                      :options="listaSexo"
                      value-field="id"
                      text-field="nombre"
                      :state="contactoPrincipal.sexo != null"
                      required
                  >
                  </b-form-select>
                  <b-form-invalid-feedback :state="contactoPrincipal.sexo != null">
                    Debe seleccionar una opción
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" class="pr-5">
                <label class="labelsF mt-5" style="font-weight: bolder"
                >Fecha de nacimiento <span style="color: red">*</span></label
                >
                <b-input-group>
                  <b-form-datepicker
                      name="fecha"
                      show-decade-nav
                      :required="true"
                      :state="
                      contactoPrincipal.fechaNacimiento ||
                      contactoPrincipal.fechaNacimiento != null
                    "
                      v-model="contactoPrincipal.fechaNacimiento"
                      @input="
                      verificarPersona()
                    "
                  ></b-form-datepicker>
                </b-input-group>
              </b-col>
               
            </b-row>     
      </div>
      
      <div class="mt-4 mb-2">
        <p class="text-center" v-if="membresias" style="color: #E17D35; font-size: .85em; font-weight: 500;">Pagos únicamente de contado</p>
        <div class="d-flex justify-content-center">
          <div class="d-flex justify-content-between buttonWidth">
              <b-button @click="guardarContactoPrincipal()" variant="info" style="min-width: 130px;" :disabled="contactoPrincipal.nombreCompleto == null || contactoPrincipal.correo == null || contactoPrincipal.telefono == null || contactoPrincipal.nombreCompleto == undefined || contactoPrincipal.correo == undefined || contactoPrincipal.telefono == undefined || (!validMail(contactoPrincipal.correo) || contactoPrincipal.telefono.length < 12 && nuevoContactoPrincipal)">{{ membresias ? 'Pasar al pago' : 'Continuar' }}</b-button>
              
              <b-button style="min-width: 130px;" @click="modalMedioContacto = false">Cancelar</b-button>
          </div>
        </div>
      </div>
    </b-modal>
   
  </div>
  </template>
  
  <script>
   import * as JSZip from "jszip";
   import { saveAs } from "file-saver";
  export default {
    name: "ListadoPlanes",
    props: {
      windowWidth: {
        type: Number
      },
      emitirContinuarProp: {
        default: false,
          type: Boolean
      },
        membresias: {
          default: false,
          type: Boolean
        },
        finCompra: {
            default: false,
            type: Boolean
        },
        formularioValido: {
            default: false,
            type: Boolean
        },
      carritoPlanes: null,
        pago: {
            type: Boolean,
            require: true,
        },
        valorDesdeResumenCompra: Number,
    },
    data: () => ({
      modalPago: false,
      metodoPagoSeleccionado: null,
      listaMetodosPago:[],
        modalMetodoPago: false,
        contactoGuardadoExitoso: false,
        nuevoContactoPrincipal: false,
        contactoPrincipal: {
            nombreCompleto: null,
            apellidoPaterno: null,
            apellidoMaterno: null,
            correo: null,
            telefono: null,
            fechaNacimiento: null,
            sexo: null,
            idPaciente: null
        },
        pacienteSeleccionado: null,
        modalMedioContacto: false,
        modalTerminos: false,
        contactoPacienteGuardado: false,
        modalContacto: false,
        total: 0,
        titulos: [
        'No.',
            { key: "Seleccion", label: "" }, 
            { key: "nombrePlan", label: "Nombre plan" },
            { key: "tipoValidacion", label: "Tipo plan" },
            { key: "numeroMaximo", label: "Número pacientes" },
        ],
        selected: null,
        planSeleccionado: 0,
      loadingPage: false,
      listaSexo: [],
      traslationsTxt: {
        countrySelectorLabel: "Código de país",
        countrySelectorError: "Elige un país",
        phoneNumberLabel: "Número de teléfono",
        example: "Ejemplo :",
      },
      codigoTelefono: null,
      membresiaTicket: null
    }),
    mounted(){
      this.membresiaTicket = this.$session.get('idTicketMembresiasDentalia') != null
    },
    created() {
      this.obtenerAvisoTerminosCondiciones();
      this.carritoPlanes = this.$session.get("carrito");
      this.calcular();
      this.$root.$on("carritonav", () => {
        this.calcular();
      });
  
      this.traerMetodosPago();
      this.traerSexo();

    },
    watch: {
    emitirContinuarProp(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.continuar();
      }
    }
  },
  computed: {
    optionsWithIndex() {
      return this.carritoPlanes.map((item, index) => {
        return {
          index: index,
          name: item.nombrePlan
        };
      });
    },
  },
    methods: {
      onUpdateTel(payload) {
        if (payload.countryCallingCode != undefined) {
          this.codigoTelefono = payload.countryCallingCode;
        }
      },
      traerSexo() {
        let url = this.$api + "catalogos/listaSexo";
        this.$http
            .post(url)
            .then((response) => {
              if (response.data.data) {
                response.data.data.unshift({
                  id: null,
                  nombre: "SEXO: *",
                });

                this.listaSexo = response.data.data;
              }
            })
            .catch((err) => {
              this.errorTraerDatos(err);
            });
      },
      desplegarPlan(index){
        this.carritoPlanes[index].planDesplegado = !this.carritoPlanes[index].planDesplegado;
      },
          traerMetodosPago() {
            let url = this.$api + "catalogos/obtenerMetodosPago";
            this.$http
                .post(url)
                .then((response) => {
                  if (response.data.data) {
                    this.listaMetodosPago = response.data.data;
                  }
                })
                .catch((err) => {
                  this.errorTraerDatos(err);
                });
        },
        guardarCertificados() {
        const isMobile =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          );
  
        if (isMobile) {
          this.carritoPlanes.forEach(planes => {
            planes.certificadosPacientes.forEach(
            (item, index) => {
              const { certificadoPdf, idPaciente } = item;
              const nombreArchivo = `certificado_${idPaciente}_${index + 1}.pdf`;
  
              const pdfBlob = atob(certificadoPdf);
              const arrayBuffer = new ArrayBuffer(pdfBlob.length);
              const uint8Array = new Uint8Array(arrayBuffer);
  
              for (let i = 0; i < pdfBlob.length; i++) {
                uint8Array[i] = pdfBlob.charCodeAt(i);
              }
  
              const pdfBlobObject = new Blob([arrayBuffer], {
                type: "application/pdf",
              });
  
              saveAs(pdfBlobObject, nombreArchivo);
            }
          );
          });
        
          this.$swal({
                icon: "success",
                title: "Aviso",
                text: "Se descargó el archivo correctamente",
                showConfirmButton: false,
                timer: 2500,
              });
        } else {
          const zip = new JSZip();
          this.carritoPlanes.forEach(planes => {
            planes.certificadosPacientes.forEach(
            (item, index) => {
              const { certificadoPdf, nombrePaciente } = item;
              const nombreArchivo = `certificado_${nombrePaciente}_${
                index + 1
              }.pdf`;
              zip.file(nombreArchivo, certificadoPdf, { base64: true });
            }
          );
          });
  
          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, "certificados.zip");
          });
  
          this.$swal({
                icon: "success",
                title: "Aviso",
                text: "Se descargó el archivo correctamente",
                showConfirmButton: false,
                timer: 2500,
              });
        }
      },
      async guardarPacienteTitular(persona) {
        const data = {
          nombre: persona.nombrePaciente,
          apelleidoPaterno: persona.apellidoP,
          apellidoMaterno: persona.apellidoM,
          sexo: persona.sexo,
          fechaNacimiento: this.$moment(persona.fechaNacimiento).format(
              "YYYY-MM-DD[T]HH:mm:ss[Z]"
          ),
          correo: persona.correo,
          telefono: persona.telefono,
          tipoAsegurado: persona.tipoAsegurado,
          tipoBeneficiario: null,
        };
        this.contactoPrincipal.idPaciente = await this.$http
            .post(this.$api + "plan/guardarPaciente", data)
            .then((response) => response.data.data)
            .finally(() => {
              this.loader = false;
            });
      },
      async guardarPaciente(persona) {
        const data = {
          nombre: persona.nombrePaciente,
          apelleidoPaterno: persona.apellidoP,
          apellidoMaterno: persona.apellidoM,
          sexo: persona.sexo,
          fechaNacimiento: this.$moment(persona.fechaNacimiento).format(
            "YYYY-MM-DD[T]HH:mm:ss[Z]"
          ),
          correo: persona.correo,
          telefono: persona.telefono,
          tipoAsegurado: persona.tipoAsegurado,
          tipoBeneficiario: null,
        };
        persona.idPaciente = await this.$http
          .post(this.$api + "plan/guardarPaciente", data)
          .then((response) => response.data.data)
          .finally(() => {
            this.loader = false;
          });
      },
        async guardarPacienteTemporal() {
            this.loader = true;
            for (let plan of this.carritoPlanes) {
                for (let paciente of plan.pacientes.filter((p) => !p.idPaciente)) {
                this.guardarPaciente(paciente);
                }
            }

            this.$session.set("carrito", this.carritoPlanes);
            this.$session.set("promocion", this.promociones);
            this.$session.set("idMsiSeleccionado", this.promociones);
            this.$session.set("pagoRecurrente", this.pagoRecurrente);
            this.$session.set("aceptaTerminos", true);
            this.crearCheckoutSession();
        },
        
        async crearCheckoutSession() {
            const url = this.$baseBack + "/api/integraciones/stripe/generar/pago";
            await this.$http
            .post(url, this.generarBodyCheckoutSession())
            .then((response) => {
                if (response.status == 200) {
                window.location.href = response.data.data.urlPago;
                }
            })
            .catch((err) => {
                console.error(err);
                this.mostrarMensajeError(err);
            });
        },
        
        generarBodyCheckoutSession() {
        let pacientesEnviar = [];
        this.carritoPlanes.forEach((element) => {
            console.info(element);
            let beneficiarios = [];
            if (element.numeroMaximo > 1) {
            const pxTitular = element.pacientes.filter(
                (elemento) => elemento.tipoAsegurado === 1
            );
            //const pxBeneficiarios = element.pacientes.filter((elemento) => elemento.tipoAsegurado === 2);
            element.pacientes.forEach((px) => {
                if (px.tipoAsegurado === 2) {
                let beneficiario = {
                    idPaciente: px.idPaciente,
                    nombreCompleto:
                    px.nombrePaciente + " " + px.apellidoM + " " + px.apellidoP,
                    nombreVendor: px.habilitarNombreVendedor
                    ? px.nombreVendedor
                    : null,
                    idTipoAsegurado: px.tipoAsegurado,
                    idDefaultPrice: element.idDefaultPriceStripe,
                    idDefaultPriceSubscription: element.idDefaultPriceStripeSubscription,
                    idPlan: element.idPlan,
                };
                beneficiarios.push(beneficiario);
                }
            });

            let pacientePlan = {
                idPaciente: pxTitular[0].idPaciente,
                nombreCompleto:
                pxTitular[0].nombrePaciente +
                " " +
                pxTitular[0].apellidoM +
                " " +
                pxTitular[0].apellidoP,
                nombreVendor: pxTitular[0].nombreVendedor,
                idTipoAsegurado: pxTitular[0].tipoAsegurado,
                idDefaultPrice: element.idDefaultPriceStripe,
                idDefaultPriceSubscription: element.idDefaultPriceStripeSubscription,
                idPlan: element.idPlan,
                precioPlan: element.precio,
                precioCotizado: element.precioSinComision,
                precioCotizadoIva: element.precioCotizadoIva,
                montoComision: element.montoComision,
                comision : element.comision,
                montofijoTransaccion: element.montofijoTransaccion,
                beneficiarios: beneficiarios,
            };
            pacientesEnviar.push(pacientePlan);
            } else {
            const px = element.pacientes[0];
            let pacientePlan = {
                idPaciente: px.idPaciente,
                nombreCompleto:
                px.nombrePaciente + " " + px.apellidoM + " " + px.apellidoP,
                nombreVendor: px.habilitarNombreVendedor ? px.nombreVendedor : null,
                idTipoAsegurado: px.tipoAsegurado,
                idDefaultPrice: element.idDefaultPriceStripe,
                idDefaultPriceSubscription: element.idDefaultPriceStripeSubscription,
                idPlan: element.idPlan,
                precioPlan: element.precio,
                precioCotizado: element.precioSinComision,
                precioCotizadoIva: element.precioCotizadoIva,
                montoComision: element.montoComision,
                comision : element.comision,
                montofijoTransaccion: element.montofijoTransaccion,
                beneficiarios: [],
            };
            pacientesEnviar.push(pacientePlan);
            }
        });
        console.log("**********")
        console.log(pacientesEnviar)
        let objetoEnviar = {
            pacientesPlan: pacientesEnviar,
            titularStripe: this.titularStripe,
            idMsiPortal: 1,
            aceptoTerminos: this.terminos,
            idMetodoPagoStripe: this.metodoPagoSeleccionado,
            idTicketMembresiasDentalia: this.$session.get("idTicketMembresiasDentalia")
        };
        return objetoEnviar;
        },

        establecerContactoPrincipal(restablecerContacto){
            this.pacienteSeleccionado = null;
            this.nuevoContactoPrincipal = restablecerContacto;
            this.contactoPrincipal = {
                nombreCompleto: null,
                apellidoPaterno: null,
                apellidoMaterno: null,
                correo: null,
                telefono: null,
                fechaNacimiento: null,
                sexo: null,
                idPaciente: null
            };
        },

        validMail(email) {
            var expr =
            /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
            return expr.test(email);
        },
      verificarPersona() {
        console.log(this.contactoPrincipal)
        this.loadingPage = true;
        if (new Date(this.contactoPrincipal.fechaNacimiento) > new Date()) {
          this.$swal({
            icon: "warning",
            title: "La fecha de nacimiento no puede ser superior a hoy",
            confirmButtonText: "OK",
          });
          this.loadingPage = false;
          setTimeout(() => {
            this.contactoPrincipal.fechaNacimiento = null;
          }, 200);
          return false;
        }else if (
            this.contactoPrincipal.nombreCompleto === "" ||
            this.contactoPrincipal.nombreCompleto === null ||
            this.contactoPrincipal.apellidoPaterno === "" ||
            this.contactoPrincipal.apellidoPaterno === null ||
            this.contactoPrincipal.apellidoMaterno === "" ||
            this.contactoPrincipal.apellidoMaterno === null ||
            this.contactoPrincipal.correo === "" ||
            this.contactoPrincipal.correo === null ||
            this.contactoPrincipal.telefono === "" ||
            this.contactoPrincipal.telefono === null ||
            this.contactoPrincipal.telefono.length < 12 ||
            !this.validMail(this.contactoPrincipal.correo) ||
            this.contactoPrincipal.sexo === "" ||
            this.contactoPrincipal.sexo === null

        ) {
          this.$swal({
            icon: "warning",
            title: "Datos faltantes",
            text: "Por favor llene antes el nombre, apellidos, sexo, correo y teléfono",
            confirmButtonText: "Entendido",
          });
          this.loadingPage = false;
          setTimeout(() => {
            this.contactoPrincipal.fechaNacimiento = null;
          }, 200);
          return false;
        } else {
          const dtoPersona = {};
          dtoPersona.nombre = this.contactoPrincipal.nombreCompleto.trim().toUpperCase();
          dtoPersona.apaterno = this.contactoPrincipal.apellidoPaterno.trim().toUpperCase();
          dtoPersona.amaterno = this.contactoPrincipal.apellidoMaterno.trim().toUpperCase();
          dtoPersona.fechaNacimiento = this.$moment(
              this.contactoPrincipal.fechaNacimiento
          ).format("YYYY-MM-DD[T]HH:mm:ss[Z]");
          dtoPersona.idPlan = 0;

          this.$http
              .post(this.$api + "plan/findPersonaCuentaConPlanBase", dtoPersona)
              .then((response) => {
                 console.info(response)

                if (response.data.data.existe.length > 0) {
                  response.data.data.existe.forEach((e) => {
                    if (e.tipoContacto === 2) {
                      this.contactoPrincipal.telefono = e.conctacto;
                    }
                    if (e.tipoContacto === 3) {
                      this.contactoPrincipal.correo = e.conctacto;
                    }
                    this.contactoPrincipal.idPaciente = e.idPaciente;
                  });
                } else {
                  const data = {
                    nombrePaciente: this.contactoPrincipal.nombreCompleto.trim().toUpperCase(),
                    apellidoP: this.contactoPrincipal.apellidoPaterno.trim().toUpperCase(),
                    apellidoM: this.contactoPrincipal.apellidoMaterno.trim().toUpperCase(),
                    sexo:this.contactoPrincipal.sexo,
                    fechaNacimiento: this.$moment(this.contactoPrincipal.fechaNacimiento).format(
                        "YYYY-MM-DD[T]HH:mm:ss[Z]"
                    ),
                    correo: this.contactoPrincipal.correo,
                    telefono: this.contactoPrincipal.telefono,
                    tipoAsegurado: null,
                    tipoBeneficiario: null,
                  }
                  this.guardarPacienteTitular(data);
                }
              })
              .catch((e) => {
                console.info(e);
              })
              .finally(() => {
                setTimeout(() => {
                  this.loadingPage = false;
                }, 500);
              });
        }
      },
        async guardarContactoPrincipal() {
        this.loader = true;
        await this.$http
          .post(
            this.$api + "integraciones/stripe/guardar/titular",
            this.contactoPrincipal
          )
          .then((response) => {
            if (response.data.data) {
              this.contactoGuardado = response.data.data;
              this.titularStripe = response.data.data;
              this.contactoGuardadoExitoso = true;
              this.loader = false;
              if(this.membresias){
                this.guardarPacienteTemporal();
              }
              this.$swal({
                icon: "success",
                title: 'Contacto principal guardado correctamente',
                showConfirmButton: false,
                timer: 2000,
            });

            this.modalMedioContacto = false;
            this.modalPago = !this.membresias ? true : false;
            this.metodoPagoSeleccionado = !this.membresias ? null : 1;
            }
          })
          .catch((err) => {
            this.errorTraerDatos(err);
            this.contactoGuardadoExitoso = false;
            this.loader = false;
            this.$swal({
                icon: "error",
                title: 'Error',
                text: "Ocurrió un error al guardar el contacto principal, por favor inténtelo más tarde",
                showConfirmButton: false,
                timer: 2000,
            });
            
          })
          .finally(() => {
            this.loader = false;
            this.modalContacto = false;
          });
      },
        seleccionarContactoPrincipal(indexPx, indexPlan , idPaciente){
            let paciente = this.carritoPlanes[indexPlan].pacientes[indexPx];
            this.contactoPrincipal = {
                nombreCompleto: paciente.nombrePaciente + ' ' + paciente.apellidoP + ' ' + paciente.apellidoM,
                correo: paciente.correo,
                telefono: paciente.telefono,
                idPaciente: idPaciente
            };
            this.pacienteSeleccionado = indexPx;
            this.planSeleccionado = indexPlan;
        },

        obtenerAvisoTerminosCondiciones() {
            const datos = {
                idAvisoSistema: 16
            }
            const url = this.$baseBack + "/api/erp/obtenerAvisoSistema";
            this.$http
                .post(url, datos)
                .then((response) => {
                if (response.data.data !== null) {
                    this.terminoCondicionesBase64 =
                    "data:application/pdf;base64, " + response.data.data;
                }
                })
                .catch((err) => {
                console.info(err);
                });
            },
        seleccionarPlan(index){
            this.planSeleccionado = index;
            this.$emit("seleccionarPlan", this.planSeleccionado)
        },
        continuar(){
            this.modalMedioContacto = true;
            this.nuevoContactoPrincipal = false;
            this.metodoPagoSeleccionado = 1;
            
        },

        abrirMedioContacto(){
            this.modalMedioContacto = true;
            this.nuevoContactoPrincipal = false;
            this.metodoPagoSeleccionado = this.membresias ? 1 : null;
      },
      onRowSelected(items) {
        
        this.selected = items;
        
        
        const indice = this.carritoPlanes.findIndex(
          (plan) => plan === this.selected[0]
        );

        console.log("indice",indice)
        this.$emit("actualizarValor", indice);
      },
      traerPacientes() {
        this.modalContacto = true;
        this.$emit("traerPacientes", true);
      },
  
      guardarCarritoTemporal() {
        let lista = this.$session.get("carrito");
        const planes = lista.map((plan) => {
          plan.pacientes = [...Array(plan.numeroMaximo).keys()].map(() => ({}));
          return plan;
        });
        this.$session.set(
          "carrito",
          planes
            .map((plan) => {
              return [...Array(plan.cantidad).keys()].map(() => ({
                ...plan,
                cantidad: 1,
              }));
            })
            .reduce((a, b) => a.concat(b))
        );
        this.$root.$emit("carritonav");
        this.$router.push({ name: "CarritoPaciente" });
      },
      calcular() {
        const carrito = this.$session.get("carrito");
        if (carrito && carrito.length > 0) {
          this.total = this.$session
            .get("carrito")
            .map((plan) => plan.cantidad * plan.precio)
            .reduce((a, b) => a + b);
        } else {
          this.total = 0;
        }
      },
    },
  };
  </script>
  
  <style>
  .height-component{
    min-height: 90vh;
    margin-bottom: 6%;
  }

  .buttonWidth{
    width: 50%;
  }

    @media (max-width: 768px) {
      .height-component{
        min-height: 8vh;
       
      }

      .buttonWidth{
        width: 70%;
      }
    
  
    }

  .scrollable-container-listado {
    overflow-y: auto; 
    max-height: 67vh; 
  }

  .scrollable-container-pacientes{
    overflow-y: auto; 
    max-height: 60vh; 
  }

  .boton-agenda-inv {
    border-radius: 50rem !important;
    color: var(--color-icono);
    background-color: white !important;
    border-color: #fff !important;
    border: 1px solid #D6E7F3;
    padding: 1%;
  }
  
  .boton-agenda-inv:active {
    border-color: var(--color-icono);
  }
  .boton_regresar_svg {
    content: url("../../assets/icons/boton_regresar.svg");
  }
  .estiloPlanSeleccionado{
    background-color: #F2F8FC;
    border: 1px solid #C2DFF1;
    border-radius: 20px;
  }

  .estiloPacienteSeleccionado{
    background-color: #F2F8FC;
    border: 1px solid #C2DFF1;
    border-radius: 20px;
    height: 30px;
  }

  .main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0px;
   }

  .button-container {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
  }

  .planHover:hover{
    color: #79B4D8;
  }
    .tabla-resumen {
  max-height: 500px; 
  overflow-y: auto; 
}

  </style>
