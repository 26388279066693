<template>
<div>
</div>
</template>

<script>
export default {
  name: "MembresiasDentalia",
  data: () => ({
    carrito: null,
    
  }),
  mounted() {
  },
  created(){
    this.verificarTokenAccesoPortal();
  },
  methods: {
    verificarTokenAccesoPortal() {
      this.$session.destroy();
      const url = this.$baseBackControlAcceso + "/login";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      };

      let parametros = {
        nombreUsuario: this.$baseMembresiasUser,
        contrasena: this.$baseMembresiasPass,
        sistema: 3
      };

      this.$http
          .post(url,parametros,  {headers})
          .then((response) => {
            this.$session.set("token", response.headers.authorization);
            this.$session.set("idControlAcceso", response.headers.CONTROL);
            //this.obtenerMembresiasDentaliaToken();
          })
          .then(() => {
            this.$http.post(this.$baseBack + '/me').then( response => {
              this.$session.start();
              this.$localStorageService.asignarUsuarioAutenticado(response.data.data);
            }).then(()=> {
              if (this.$localStorageService.getError()) {
                this.mostrarMensajeError(
                    this.$localStorageService.getTipoError()
                );
              } else {
                this.$session.set('carrito', this.carrito);
                this.$router.push({ name: "ConfiguracionMembresia" });
              }
            })
            setTimeout(()=>{
              if(this.$localStorageService.getError() == false){
                //this.guardarBitacora("EXITO", this.$localStorageService.getIdUsuario());
              }
            },5000);
          })
          .catch((err) => {
            this.mostrarMensajeError(this.mensajeError);
            console.error(err);
            //this.blnContUsu = true;
            //this.guardarBitacora("USUARIO Y CONTRASEÑA INCORRECTA", null);
          })
          .finally(() => {
            this.guardarMembresiasDentalia();
            setTimeout(()=>{
              if(this.$localStorageService.getTipoError().toUpperCase().indexOf("LA CAMPAÑA HA TERMINADO") >= 0 && !this.blnContUsu){
                //this.guardarBitacora("LA CAMPAÑA HA TERMINADO", this.$localStorageService.getIdUsuario());
              }
            }, 5000);
          });
    },
    obtenerMembresiasDentaliaIdTicket(idTickeMembresiaDentalia) {
      let url = this.$baseBack + '/api/membresiasDentalia/obtenerCompraMembresiasDentalia';
      return this.$http.post(url, {idTickeMembresiaDentalia})
          .then((response) => {
            this.carrito = response.data.data;
          })
          .catch((e) => {
            this.onError(e);
            return null;
          })

    },
    async guardarMembresiasDentalia() {
      let body = await this.compraLista();
      let url = this.$baseBack + '/api/ws/guardaCompraMembresiasDentalia';
      return this.$http.post(url, body)
          .then((response) => {
            console.info(response.data.data)
            this.obtenerMembresiasDentaliaIdTicket(response.data.data.idTicketMembresiasDentalia);
            this.$session.set("idTicketMembresiasDentalia", response.data.data.idTicketMembresiasDentalia);

          })
          .catch((e) => {
            this.onError(e);
            return null;
          })

    },
    async compraLista(){
      let listaCompra = [];
      let token = this.$route.params.token;
      let partes = token.split('&');
      let planesMembresia = ['52848','52847','52846','52845','52844'];
      partes.forEach((p, index)=>{
        // eslint-disable-next-line no-unused-vars
        let idPlan;
        // eslint-disable-next-line no-unused-vars
        let cantidad;
        let clave = p.split('=');
        if(clave[0].includes("idPlan")){
          idPlan = clave[1];
          if(planesMembresia.includes(idPlan)){
            if (partes[index+1].includes("cantidad")){
              let clave2 = partes[index+1].split('=');
              cantidad = clave2[1];
            }
            listaCompra.push(
                {
                  "idPlan": idPlan,
                  "cantidad": cantidad
                }
            )
          }         
        }
      })
      let body = {
        "url": token,
        "productos": listaCompra
      }
      return body;
    }
  }
}
</script>
<style scoped>

</style>
